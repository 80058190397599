import React, { Component } from 'react';
import flvjs from 'flv.js';

class VideoView extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
    };
  }

  componentDidMount = () => {
    if (flvjs.isSupported()) {
      const flvPlayer = flvjs.createPlayer({
        type: 'flv',
        url: `${this.props.contentvideo}#t=0.1`,
        lazyLoadMaxDuration: 10,
      });
      this.flvPlayer = flvPlayer;
      this.flvPlayer.attachMediaElement(this.myRef.current);
      this.flvPlayer.load();
    }
  }

  componentWillUnmount() {
    if (this.flvPlayer) {
      this.flvPlayer.pause();
      this.flvPlayer.unload();
      this.flvPlayer.detachMediaElement();
      this.flvPlayer.destroy();
    }
  }

  render() {
    if (!this.props.contentvideo) {
      return null;
    }
    return (
      <video
        preload="metadata"
        controls
        ref={this.myRef}
        style={{ width: '100%' }}
      >
        Your browser does not support the video tag.
      </video>
    );
  }
}

VideoView.propTypes = {

};

export default VideoView;
