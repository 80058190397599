import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../actions';
import { Link } from 'react-router-dom';

const GeeksPage = ({ leetcodes }) => (
  <div className="w-60-l center mt0 mb5">
    <table className="w-100-l center">
      <thead>
        <tr>
          <th className="w-30-l tc">#</th>
          <th className="w-30-l tc">Title</th>
          <th className="w-30-l tc">Difficulty</th>
        </tr>
      </thead>
      <tbody>
        {leetcodes.map(leetcode => (
          <tr>
            <td className="w-30-l tc">{leetcode.number}</td>
            <td className="w-30-l tc">
              <Link to={{ pathname: `/geeks/${leetcode.filename}` }}>{leetcode.title}</Link>
            </td>
            <td className="w-30-l tc">{leetcode.difficulty}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

function mapStateToProps(state) {
  return {
    leetcodes: state.leetcodes,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(GeeksPage);
