import 'whatwg-fetch';

class HttpRequest {
  // If we are getting the status but not getting the Json response from App Server
  static request(url, params) {
    return fetch(url, {
      method: params.method,
      headers: params.header,
      body: params.body,
    }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      }
      throw response;
    });
  }
}

export default HttpRequest;
