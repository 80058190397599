import { combineReducers } from 'redux';

/*
 * All reducers get two parameters passed in, state and action that occurred
 *       > state isn't entire apps state, only the part
 * of state that this reducer is responsible for
 */
// "state = null" is set so that we don't throw an error when app first boots up
function BlogListReducer(state = [], action) {
  switch (action.type) {
    case 'GOT_BLOG_LIST':
      return action.payload;
    default:
      break;
  }
  return state;
}

function LeetcodeListReducer(state = [], action) {
  switch (action.type) {
    case 'GOT_LEETCODE_LIST':
      return action.payload;
    default:
      break;
  }
  return state;
}

function MediaListReducer(state = [], action) {
  switch (action.type) {
    case 'GOT_MEDIA_LIST':
      return action.payload;
    default:
      break;
  }
  return state;
}

function TweetListReducer(state = [], action) {
  switch (action.type) {
    case 'GOT_TWEET_LIST':
      return action.payload;
    default:
      break;
  }
  return state;
}

function FeedReducer(state = {}, action) {
  switch (action.type) {
    case 'GOT_FEED':
      return action.payload;
    default:
      break;
  }
  return state;
}

/*
 * We combine all reducers into a single object before updated data is dispatched (sent) to store
 * Your entire applications state (store) is just whatever gets returned from all your reducers
 */
const allReducers = combineReducers({
  blogs: BlogListReducer,
  leetcodes: LeetcodeListReducer,
  media: MediaListReducer,
  tweets: TweetListReducer,
  feed: FeedReducer,
});

export default allReducers;
