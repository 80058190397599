import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../actions';

import ReactMarkdown from 'react-markdown';

class MarkdownPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const feedid = this.props.match.params.id;
    this.props.actions.GetFeed(feedid);
  };

  render() {
    return (
      // TODO
      <div className="center w-40-l mt0 mb5">
        <div className="markdown-body">
          <ReactMarkdown source={this.props.feed.__content} />
        </div>
      </div>
    );
  }
}

MarkdownPage.propTypes = {};

function mapStateToProps(state) {
  return {
    feed: state.feed,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(MarkdownPage);
