const dev = process.env.NODE_ENV === "development";

const config = {
  dev,
  hostname: dev ? "https://localhost:3000" : "https://blog.yourdan.com",
  navbar: dev
    ? ["blogs", "geeks", "media", "tweets"]
    : ["blogs", "geeks", "media", "tweets"],
};

module.exports = { config };
