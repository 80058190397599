import { call, put, takeLatest, take, select, all } from 'redux-saga/effects';
import * as types from './types';
import HttpRequest from './utils/HttpRequest';
import { combineAllFeeds } from './selectors';
import { config } from './config';

const baseurl = config.dev ? 'http://localhost:8000' : window.location.origin;
const blogsapi = `${baseurl}/api/blogs`;
const geeksapi = `${baseurl}/api/geeks`;
const mediaapi = `${baseurl}/api/media`;
const tweetsapi = `${baseurl}/api/tweets`;
const defaultRequestParams = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};

function* GetBlogList(action) {
  try {
    const blogs = yield call(HttpRequest.request, blogsapi, defaultRequestParams);

    yield put({ type: types.GOT_BLOG_LIST, payload: blogs || [] });
  } catch (error) {
    yield put({ type: types.GOT_BLOG_LIST, payload: [], message: error });
  }
}
function* GetBlogListWatcher() {
  yield takeLatest(types.GET_BLOG_LIST, GetBlogList);
}

function* GetLeetcodeList(action) {
  try {
    const leetcodes = yield call(HttpRequest.request, geeksapi, defaultRequestParams);

    yield put({ type: types.GOT_LEETCODE_LIST, payload: leetcodes || [] });
  } catch (error) {
    yield put({ type: types.FETCH_FAILED, message: error.message });
  }
}
function* GetLeetcodeListWatcher() {
  yield takeLatest(types.GET_LEETCODE_LIST, GetLeetcodeList);
}

function* GetMediaList(action) {
  try {
    const media = yield call(HttpRequest.request, mediaapi, defaultRequestParams);

    yield put({ type: types.GOT_MEDIA_LIST, payload: media || [] });
  } catch (error) {
    yield put({ type: types.FETCH_FAILED, message: error.message });
  }
}
function* GetMediaListWatcher() {
  yield takeLatest(types.GET_MEDIA_LIST, GetMediaList);
}

function* GetTweetList(action) {
  try {
    const tweets = yield call(HttpRequest.request, tweetsapi, defaultRequestParams);

    yield put({ type: types.GOT_TWEET_LIST, payload: tweets || [] });
  } catch (error) {
    yield put({ type: types.FETCH_FAILED, message: error.message });
  }
}
function* GetTweetListWatcher() {
  yield takeLatest(types.GET_TWEET_LIST, GetTweetList);
}

function* GetFeed(action) {
  try {
    let state = yield select(combineAllFeeds);
    if (!state.length) {
      yield take(types.GOT_ALL);
      state = yield select(combineAllFeeds);
    }
    const feed = state.find(entry => entry.filename === action.id);

    yield put({ type: types.GOT_FEED, payload: feed || {} });
  } catch (e) {
    yield put({ type: types.FETCH_FAILED, message: e.message });
  }
}

function* GetFeedWatcher() {
  yield takeLatest(types.GET_FEED, GetFeed);
}

function* GetAll(action) {
  try {
    yield all([
      put({ type: types.GET_LEETCODE_LIST }),
      put({ type: types.GET_BLOG_LIST }),
      put({ type: types.GET_MEDIA_LIST }),
      put({ type: types.GET_TWEET_LIST }),
    ]);

    yield all([
      take(types.GOT_LEETCODE_LIST),
      take(types.GOT_BLOG_LIST),
      take(types.GOT_MEDIA_LIST),
      take(types.GOT_TWEET_LIST),
    ]);

    yield put({ type: types.GOT_ALL });
  } catch (e) {
    yield put({ type: types.FETCH_FAILED, message: e.message });
  }
}

function* GetAllWatcher() {
  yield takeLatest(types.GET_ALL, GetAll);
}

export default [
  GetBlogListWatcher,
  GetLeetcodeListWatcher,
  GetMediaListWatcher,
  GetTweetListWatcher,
  GetFeedWatcher,
  GetAllWatcher,
];
