import React from 'react';

const Footer = () => (
  <footer className="site-footer fixed w-100 bottom-0 h3-l pa0">
    <div className="wrapper">
      <div className="footer-col-wrapper">
        <div className="footer-col center">
          <ul className="contact-list">
            <li>d(`･∀･)b</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default React.memo(Footer);
