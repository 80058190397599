import * as types from './types';

const GetFeed = id => ({ type: types.GET_FEED, id });
const GetAll = () => ({ type: types.GET_ALL });
const GetBlogList = () => ({ type: types.GET_BLOG_LIST });
const GetLeetcodeList = () => ({ type: types.GET_LEETCODE_LIST });
const GetMediaList = () => ({ type: types.GET_MEDIA_LIST });

const ActionCreators = Object.assign({
  GetAll,
  GetBlogList,
  GetLeetcodeList,
  GetMediaList,
  GetFeed,
});

export default ActionCreators;
