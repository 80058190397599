import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import ActionCreators from '../../actions';
import { Link } from 'react-router-dom';
import './styles.scss';

const BlogsPage = ({ blogs }) => (
  <div className="center w-40-l mt0 mb5">
    <ul className="posts">
      {blogs.map(blog => (
        <li>
          <div className="post-meta">
            <Link to={{ pathname: `/blogs/${blog.filename}` }}>
              <h3 className="post-title">{blog.title}</h3>
            </Link>
            <div className="post-date">{moment(blog.date).format('ll')}</div>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

function mapStateToProps(state) {
  return {
    blogs: state.blogs,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(BlogsPage);
