import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../actions';

import Lightbox from 'react-images';

import './styles.scss';

class MediaPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
      images: [],
    };
  }

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };

  gotoImage = index => {
    this.setState({
      currentImage: index,
    });
  };

  handleClickImage = () => {
    if (this.state.currentImage === this.state.images.length - 1) return;

    this.gotoNext();
  };

  static getDerivedStateFromProps(props, state) {
    let images = (props.media || [])
      .filter(feed => feed.layout === 'image')
      .map(feed => feed.contentimages)
      .flat();
    return {
      images: images,
    };
  }

  // Credit to https://github.com/xieranmaya/blog/issues/4
  render() {
    return (
      <section className="section">
        {this.state.images.map((img, index) => (
          <div
            className="div"
            style={{
              width: (img.width * 200) / img.height,
              flexGrow: (img.width * 200) / img.height,
            }}
          >
            <i
              className="i"
              style={{
                paddingBottom: (img.height / img.width) * 100 + '%',
              }}
            />
            <img className="img" src={img.src} onClick={e => this.openLightbox(index, e)} />
          </div>
        ))}
        <Lightbox
          currentImage={this.state.currentImage}
          images={this.state.images}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
        />
      </section>
    );
  }
}

MediaPage.propTypes = {};

function mapStateToProps(state) {
  return {
    media: state.media,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(MediaPage);
