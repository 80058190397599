import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../actions';
import RowView from '../../components/RowView';
import './styles.scss';

class TweetsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      tweetsPerPage: 10,
      commentseed: null,
    };
  }

  componentDidMount() {
    this.props.match.params.id
      ? this.setState({
          page: parseInt(this.props.match.params.id, 10),
        })
      : this.setState({
          page: 1,
        });
  }

  componentWillReceiveProps = (nextProps, nextState) => {
    nextProps.match.params.id
      ? this.setState({
          page: parseInt(nextProps.match.params.id, 10),
        })
      : this.setState({
          page: 1,
        });
  };

  setcommentseed = seed => {
    this.setState({
      commentseed: seed === this.state.commentseed ? null : seed,
    });
  };

  nextpage = () => {
    const page = this.state.page + 1;
    window.history.pushState(null, null, `/tweets/page/${page}`);
    this.setState({
      page,
    });
  };

  previouspage = () => {
    const page = this.state.page - 1;
    window.history.pushState(null, null, `/tweets/page/${page}`);
    this.setState({
      page,
    });
  };

  render() {
    const FeedNodes = (this.props.tweets || [])
      .slice(
        (this.state.page - 1) * this.state.tweetsPerPage,
        this.state.page * this.state.tweetsPerPage,
      )
      .map((feed, i) => (
        <RowView
          i={i}
          key={feed.filename}
          filename={feed.filename}
          date={feed.date}
          title={feed.title}
          preview={feed.preview}
          content={feed.content}
          layout={feed.layout}
          contentlink={feed.contentlink}
          contentimages={feed.contentimages}
          contentvideo={feed.contentvideo}
          comments={this.state.commentseed && this.state.commentseed === feed.filename}
          setcommentseed={this.setcommentseed}
        />
      ));
    return (
      <main className="page-content mb5">
        <div className="wrapper">
          <div className="home">
            <ul className="post-list">{FeedNodes}</ul>
            <div disabled={this.state.page === 1} onClick={this.previouspage}>
              Previous
            </div>
            <div
              disabled={
                !(this.state.page * this.state.tweetsPerPage < (this.props.tweets || []).length)
              }
              onClick={this.nextpage}
            >
              Next
            </div>
          </div>
        </div>
      </main>
    );
  }
}

TweetsPage.propTypes = {};

function mapStateToProps(state) {
  return {
    tweets: state.tweets,
  };
}

function matchDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(TweetsPage);
