export const GET_ALL = 'GET_ALL';
export const GOT_ALL = 'GOT_ALL';

export const GET_BLOG_LIST = 'GET_BLOG_LIST';
export const GOT_BLOG_LIST = 'GOT_BLOG_LIST';

export const GET_LEETCODE_LIST = 'GET_LEETCODE_LIST';
export const GOT_LEETCODE_LIST = 'GOT_LEETCODE_LIST';

export const GET_MEDIA_LIST = 'GET_MEDIA_LIST';
export const GOT_MEDIA_LIST = 'GOT_MEDIA_LIST';

export const GET_TWEET_LIST = 'GET_TWEET_LIST';
export const GOT_TWEET_LIST = 'GOT_TWEET_LIST';

export const GET_FEED = 'GET_FEED';
export const GOT_FEED = 'GOT_FEED';

export const FETCH_FAILED = 'FETCH_FAILED';
