import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import VideoView from '../VideoView';
import LinkView from '../LinkView';
import ImageView from '../ImageView';
// const LinkView = React.lazy(() => import('./LinkView'));
// const ImageView = React.lazy(() => import('./ImageView'));

class RowView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.myref = React.createRef();
  }

  componentDidMount = () => {
    this.renderComments();
  };

  componentDidUpdate = () => {
    this.renderComments();
  };

  toggleComments = () => {
    if (this.props.setcommentseed) {
      this.props.setcommentseed(this.props.filename);
    }
  };

  renderComments = () => {
    if (this.props.comments) {
    }
  };

  renderMedia = () => {
    switch (this.props.layout) {
      case 'video':
        return <VideoView {...this.props} />;
      case 'link':
        return <LinkView {...this.props} />;
      case 'image':
        return <ImageView {...this.props} />;
      // return import('./ImageView').then(({ default: ImageView }) => <ImageView {...this.props} />).catch(error => 'An error occurred while loading the component');
      default:
        return null;
    }
  };

  render() {
    // console.log('this.myref', this.myref);
    const fromNow = moment(this.props.date).fromNow();
    return (
      <article ref={this.myref} className="tw-block-parent">
        <div className="timeline-tweetlist-tweet">
          <div className="timeline-tweet">
            <div className="timeline-tweet-author">
              <div className="tweetauthor">
                <span className="tweetauthor-avatar">
                  <div className="avatar" />
                </span>
                <span className="tweetauthor-name">{this.props.title}</span>
                <span className="timeline-tweet-timestamp">{fromNow}</span>
              </div>
            </div>
            <div className="timeline-tweet-text">
              <div
                onClick={this.toggleComments}
                dangerouslySetInnerHTML={{
                  __html: this.props.comments ? this.props.content : this.props.preview,
                }}
              />
              {this.renderMedia()}
              {this.props.comments && <div id="comments" />}
            </div>
          </div>
        </div>
      </article>
    );
  }
}

// <Link to={{ pathname: `/blogs/${this.props.filename}`, blogdetails: this.props }}>
//   {this.props.title}
// </Link>;

RowView.propTypes = {};

export default RowView;
