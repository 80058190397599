import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import { config } from './config';

import App from './App';

import allReducers from './reducers';
import allSagas from './sagas';

const loggerMiddleware = createLogger({ predicate: (getState, action) => false });
const sagaMiddleware = createSagaMiddleware();

const ConfigureStore = (initialState = {}) => {
  const composeEnhancers = (config.dev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
  const enhancer = composeEnhancers(applyMiddleware(loggerMiddleware, sagaMiddleware));
  const store = createStore(allReducers, initialState || {}, enhancer);
  store.runSaga = sagaMiddleware.run;
  return store;
};

const store = ConfigureStore({});
allSagas.forEach(saga => store.runSaga(saga));

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
