import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from './actions';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import './styles/minima.scss';

import NavBar from './components/NavBar';
import Footer from './components/Footer';

import TweetsPage from './pages/TweetsPage';
import BlogsPage from './pages/BlogsPage';
import MediaPage from './pages/MediaPage';
import GeeksPage from './pages/GeeksPage';
import MarkdownPage from './pages/MarkdownPage';

const redirectToHome = () => <Redirect to="/" />;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.actions.GetAll();
  };

  render() {
    return (
      <BrowserRouter>
        <>
          <NavBar />
          <Switch>
            <Route exact path="/" component={BlogsPage} />

            <Route exact path="/tweets" component={TweetsPage} />
            <Route path="/tweets/page/:id" component={TweetsPage} />

            <Route exact path="/blogs" component={BlogsPage} />
            <Route path="/blogs/:id" component={MarkdownPage} />

            <Route exact path="/geeks" component={GeeksPage} />
            <Route path="/geeks/:id" component={MarkdownPage} />

            <Route exact path="/media" component={MediaPage} />

            <Route render={redirectToHome} />
          </Switch>
          <Footer />
        </>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function matchDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ActionCreators, dispatch),
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps,
)(App);
