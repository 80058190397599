import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Lightbox from 'react-images';

class ImageView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0,
    };
  }

  openLightbox = (index, event) => {
    event.preventDefault();
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
    });
  }

  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }

  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }

  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }

  gotoImage = (index) => {
    this.setState({
      currentImage: index,
    });
  }

  handleClickImage = () => {
    if (this.state.currentImage === this.props.contentimages.length - 1) return;

    this.gotoNext();
  }

  renderOneRow = row => (
    <Row>
      {row}
    </Row>
  )

  renderAllRows = rows => rows.map(row => this.renderOneRow(row))

  render() {
    // const images = (this.props.contentimages || []).map(image => ({
    const n = (this.props.contentimages || []).length;
    let numberPerRow;
    if (n > 4) {
      numberPerRow = 3;
    } else if (n > 1) {
      numberPerRow = 2;
    } else {
      numberPerRow = 1;
    }
    const alignmentSize = Math.floor(12 / numberPerRow);
    const rows = [];
    for (let i = 0; i < n; i += 1) {
      const rowindex = Math.floor(i / numberPerRow);
      // First one to initialize
      if (i % numberPerRow === 0) {
        rows[rowindex] = [];
      }
      rows[rowindex].push(
        <Col xs={alignmentSize} md={alignmentSize}>
          <img src={this.props.contentimages[i].src}
            onClick={e => this.openLightbox(i, e)}
          />
        </Col>
      );
    }
    return (
      <Grid fluid>
        { this.renderAllRows(rows) }
        <Lightbox
          currentImage={this.state.currentImage}
          images={this.props.contentimages}
          isOpen={this.state.lightboxIsOpen}
          onClickImage={this.handleClickImage}
          onClickNext={this.gotoNext}
          onClickPrev={this.gotoPrevious}
          onClickThumbnail={this.gotoImage}
          onClose={this.closeLightbox}
        />
      </Grid>
    );
  }
}

ImageView.propTypes = {

};

export default ImageView;
